<template>
  <div>
    <v-alert 
      type="error"
    >
      Accès non autorisé
    </v-alert>
  </div>
</template>

<script>

export default {
};
</script>
